<template>
    <header class="global-header container" aria-label="Site header" role="banner">
        <a href="#main-content" class="skip-navigation">
            Skip to main content
        </a>
        <div class="global-header__main-menu">
            <SiteLogo />

            <div class="global-header__close">
                <a href="#previous-page" class="global-header__close-btn">
                    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="33" height="34" viewBox="0 0 33 34">
                        <path fill="none" stroke="#fff" strokeLinecap="square" strokeMiterlimit="20" strokeWidth="2"
                            d="M2.154 3.154L31 32M1.88 31.118L30.998 2" />
                    </svg>
                </a>
            </div>
        </div>
    </header>
</template>