<template>
    <SearchHeader />
    <main id="main-content">
        <slot />
    </main>
    <RenderCacheable>
        <AppFooter />
    </RenderCacheable>
</template>

<script lang="ts" setup>
useHead(() => {
    return {
        title: "Search Page",
        bodyAttrs: {
            class: "detail-page"
        }
    }
})
</script>